




























































































































































































































































































































import { defineComponent, ref, Ref, onMounted } from '@vue/composition-api'
import { debounce, delay, sum, orderBy } from 'lodash'
import {
  urlPath,
  endpoints,
  groupMultiLevel,
  groupTwoLevel,
  showError,
  toCamelCase,
  convertToWidthCharacter,
  expandAll,
  collapseAll,
  mappingStock,
  frameBusEvent,
  ORDER_DES
} from 'utils'

import {
  SectionHeader,
  LoaderComponent,
  StepButton,
  MultiLevelList,
  AddButton,
  SingleDetailItem,
  MultiLevelItem,
  MultiLevelListJsx,
  SettingViewDialog,
  DateRange,
  ConfirmDelete
} from 'components'
import { api, framebus } from 'plugins'
import moment from 'moment'
import { SelectTab, ChildrenByCustomer, ChildrenByItem, OrderDetail } from 'typings'

interface SetUp {
  date: Ref<string>
  showBy: Ref<string>
  selectItems: Ref<Array<SelectTab>>
  searchInfo: Ref<string>
  searchHistory: Ref<string>
  dataShowListByCustomer: Ref<Array<ChildrenByCustomer>>
  dataShowListByProduct: Ref<Array<ChildrenByItem>>
  panelStateProduct: Ref<any>
  panelStateCustomer: Ref<any>
  loading: Ref<boolean>
  goToOrderDetail: (orderId: number, orderDetailId: number, isDuplicated: boolean | null) => void
  selectedOrderId: Ref<number | null>
  selectedOrderDetailId: Ref<number | null>
  handleCreateOrderAndOrderDetail: () => void
  movePanelStateCustomerToState: (data: any) => void
  movePanelStateProductToState: (data: any) => void
  isShowAction: Ref<boolean>
  handleShowAction: (level1: string, level2: string, level3: string, isByCustomer: boolean) => void
  handleExpandAll: () => void
  handleCollapseAll: () => void
  rewriteUrl: () => void
  handleSearch: (searchInfo: string) => void
  searchOrderByHistory: () => void
  auctionDateStart: Ref<string>
  auctionDateEnd: Ref<string>
  auctionDateStartModal: Ref<boolean>
  auctionDateEndModal: Ref<boolean>
  auctionDateOrderModal: Ref<boolean>
  updateAuctionDateStart: () => void
  begin: () => void
  reloadPackingResult: () => void
  isShowSetting: Ref<boolean>
  isShowByBox: Ref<boolean>
  isShowTotal: Ref<boolean>
  isShowRemaining: Ref<boolean>
  updateSetting: (
    isShowTotalValue: boolean,
    isShowByBoxValue: boolean,
    isShowRemainingValue: boolean
  ) => void
  goToDate: (isAuction: boolean) => void
  dateRange: Ref<Array<string>>
  getNextAuctionDate: () => void
  orderDetailsByHistory: Ref<Array<any>>
  setDate: (start: string, end: string) => void
  treeviewLevel: Ref<number>
  getSettingLevel: () => void
  showDelete: Ref<boolean>
  confirmDelete: (action: string) => Promise<void>
}

const OrderByAuction = defineComponent({
  components: {
    SectionHeader,
    LoaderComponent,
    StepButton,
    MultiLevelList,
    AddButton,
    SingleDetailItem,
    MultiLevelItem,
    MultiLevelListJsx,
    SettingViewDialog,
    DateRange,
    ConfirmDelete
  },
  setup(props, { root }): SetUp {
    const { $toast, $router, $route, $store } = root
    const loading = ref(false)
    const isFromCalendar = $route.query.fromCalendar
    // if data store available then will use data in store
    // check in store, if data available then get auction_date, packing_range from store
    const date = ref(
      $store.state.common.latestSelectedDates.auctionDate
        ? $store.state.common.latestSelectedDates.auctionDate
        : $route.params.date || moment(new Date()).format('YYYY-MM-DD')
    )
    const auctionDateStart = ref(
      $store.state.common.latestSelectedDates.packingDateRange
        ? $store.state.common.latestSelectedDates.packingDateRange[0]
        : $route.query.startDate || moment(new Date()).format('YYYY-MM-DD')
    )
    const auctionDateEnd = ref(
      $store.state.common.latestSelectedDates.packingDateRange
        ? $store.state.common.latestSelectedDates.packingDateRange[1]
        : $route.query.endDate || moment(new Date()).format('YYYY-MM-DD')
    )
    // if go to page from calendar page, get date from url
    if (isFromCalendar) {
      date.value = $route.params.date
      auctionDateStart.value = $route.query.startDate
      auctionDateEnd.value = $route.query.endDate
    }

    const dataShowListByCustomer = ref<Array<ChildrenByCustomer>>([])
    const dataShowListByProduct = ref<Array<ChildrenByItem>>([])
    const orderDetails = ref([])
    const orderDetailsSearch = ref([])
    const orderDetailsByHistory = ref([])
    const selectedOrderId = ref(null)
    const selectedOrderDetailId = ref(null)

    const packingResultsData = ref([])
    const harvestResultsData = ref([])
    const assignmentsData = ref([])
    const orderDetailsBy = ref<Array<any>>([])
    const panelStateProduct = ref({ ...$store.state.panelStateProduct })
    const panelStateCustomer = ref({ ...$store.state.panelStateCustomer })
    const showBy = ref<string | any>($route.query.showBy ? $route.query.showBy : 'CUSTOMER')
    const isShowAction = ref(false)

    const searchInfo = ref<string | any>($route.query.searchInfo ? $route.query.searchInfo : '')

    const searchHistory = ref<string | any>($route.query.searchByHistory ?? '')
    const thisCustomerName = ref<string>('')
    const thisItemName = ref<string>('')
    const thisVarietyName = ref<string>('')
    const thisQualitySizeName = ref<string>('')
    const isThisByCustomer = ref(false)

    const auctionDateStartModal = ref(false)
    const dateRange = ref(['', ''])
    dateRange.value = [auctionDateStart.value, auctionDateEnd.value]
    const auctionDateEndModal = ref(false)
    const auctionDateOrderModal = ref(false)
    const isShowByBox = ref(false)
    const isShowTotal = ref(true)
    const isShowRemaining = ref(false)
    const isShowSetting: Ref<boolean> = ref(false)
    const nextAuctionDate = ref('')
    const showDelete = ref(false)
    const treeviewLevel = ref(
      Number(
        $store.state.common.settingMaster.find((e: any) => e.key === 'treeview_level')?.value
      ) || 4
    )

    const getNextAuctionDate = async () => {
      try {
        const data = await api.get(`${endpoints.NOSALE_DATE}/next_auction_date`)
        nextAuctionDate.value = data.data.date
        date.value = nextAuctionDate.value as string
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }

    const getSettingDefault = async (): Promise<void> => {
      try {
        const response = await api.get(`${endpoints.COMMON}get_setting`)
        const settingMember = response.data.setting.order
        isShowByBox.value = settingMember?.is_show_by_box || false
        isShowTotal.value = settingMember ? settingMember.is_show_total : true
        isShowRemaining.value = settingMember?.is_show_remaining || false
      } catch (e) {
        showError(e, $toast, root.$t('setting.error.get_default_setting_fail') as string)
      }
    }

    const getSettingLevel = (): void => {
      treeviewLevel.value =
        Number(
          $store.state.common.settingMaster.find((e: any) => e.key === 'treeview_level')?.value
        ) || 4
    }

    const selectItems = ref([
      { name: root.$t('customer') as string, key: 'CUSTOMER' },
      { name: root.$t('product') as string, key: 'PRODUCT' },
      { name: root.$t('history') as string, key: 'HISTORY' }
    ])

    const updateAuctionDateStart = () => {
      if (auctionDateStart.value > auctionDateEnd.value) {
        auctionDateEnd.value = auctionDateStart.value
      }
    }

    const getOrderDetailAssign = async () => {
      try {
        const data = await Promise.all([
          api.get(
            `${endpoints.ORDER_DETAILS}?auction_date=${date.value}&order_by_customer=${
              showBy.value === 'CUSTOMER'
            }`
          ),
          api.get(`${endpoints.ASSIGNMENTS}?auction_date=${date.value}`)
        ])
        const [{ data: orderDetail }, { data: assignment }] = data
        orderDetails.value = toCamelCase(orderDetail).map((e: any) => ({
          ...e,
          orderedSpecialStems: e.boxes ? 0 : e.stems
        }))
        orderDetailsSearch.value = JSON.parse(JSON.stringify(orderDetails.value))
        assignmentsData.value = toCamelCase(assignment)
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      }
    }

    const getPackingHarvest = async () => {
      try {
        const data = await Promise.all([
          api.get(
            `${endpoints.PACKING_RESULTS}get_packing_result_by_range?start_date=${auctionDateStart.value}&end_date=${auctionDateEnd.value}`
          ),
          api.get(
            `${endpoints.HARVEST_RESULT}?start_date=${auctionDateStart.value}&end_date=${auctionDateEnd.value}`
          )
        ])
        const [{ data: packingResult }, { data: harvestResult }] = data

        packingResultsData.value = toCamelCase(packingResult)
        harvestResultsData.value = toCamelCase(harvestResult)
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      }
    }

    const getAssignStems = (orderDetailId: number) => {
      const data = assignmentsData.value.filter((assign: any) => {
        return assign.orderDetail === orderDetailId
      })
      return sum(data.map((e: any) => e.stems))
    }

    const getAssignBoxes = (orderDetailId: number) => {
      const data = assignmentsData.value.filter((assign: any) => {
        return assign.orderDetail === orderDetailId
      })
      return sum(data.map((e: any) => e.boxes))
    }

    const rewriteUrl = () => {
      delay(
        () => {
          $router
            .replace({
              name: urlPath.ORDER_BY_AUCTION.name,
              params: { date: date.value.toString() },
              query: {
                startDate: auctionDateStart.value,
                endDate: auctionDateEnd.value,
                showBy: showBy.value,
                searchInfo: searchInfo.value,
                searchHistory: searchHistory.value,
                called: 'true'
              }
            })
            .catch((err) => {
              // Ignore the vuex err regarding navigating to the page they are already on.
              if (
                err.name !== 'NavigationDuplicated' &&
                !err.message.includes('Avoided redundant navigation to current location')
              ) {
                // But print any other errors to the console
                console.log(err)
              }
            })
        },
        100,
        true
      )
    }

    const searchOrderByHistory = debounce(() => {
      orderDetailsByHistory.value = orderBy(
        orderDetails.value.map((orderDetail: any) => {
          return {
            ...orderDetail,
            orderStems: orderDetail.stems,
            orderBoxes: orderDetail.boxes,
            customer: orderDetail.order.customer
          }
        }),
        'id',
        'desc'
      ) as any
      if (searchHistory.value !== '') {
        searchHistory.value = convertToWidthCharacter(searchHistory.value, 'full')
        const orderDetailsStartWith = orderDetailsByHistory.value.filter((e: OrderDetail) => {
          const text = searchHistory.value.toLowerCase()
          return (
            e.item.name?.toLowerCase().startsWith(text) ||
            e.variety.name?.toLowerCase().startsWith(text) ||
            e.size.name?.toLowerCase().startsWith(text) ||
            e.quality.name?.toLowerCase().startsWith(text)
          )
        })
        const orderDetailsIncludes = orderDetailsByHistory.value.filter((e: OrderDetail) => {
          const text = searchHistory.value.toLowerCase()
          return (
            (e.item.name?.toLowerCase().includes(text) ||
              e.variety.name?.toLowerCase().includes(text) ||
              e.size.name?.toLowerCase().includes(text) ||
              e.quality.name?.toLowerCase().includes(text)) &&
            !(
              e.item.name?.toLowerCase().startsWith(text) ||
              e.variety.name?.toLowerCase().startsWith(text) ||
              e.size.name?.toLowerCase().startsWith(text) ||
              e.quality.name?.toLowerCase().startsWith(text)
            )
          )
        })
        orderDetailsByHistory.value = orderBy(
          [...orderDetailsStartWith, ...orderDetailsIncludes],
          'id',
          'desc'
        )
      }
      rewriteUrl()
    }, 500)

    const insertPackingStems = () => {
      dataShowListByCustomer.value = mappingStock(
        dataShowListByCustomer.value,
        {
          packingResults: packingResultsData.value,
          harvestResults: harvestResultsData.value
        },
        treeviewLevel.value === 2,
        ORDER_DES
      )

      dataShowListByProduct.value = mappingStock(
        dataShowListByProduct.value,
        {
          packingResults: packingResultsData.value,
          harvestResults: harvestResultsData.value
        },
        treeviewLevel.value === 2,
        ORDER_DES
      )
    }

    const processData = () => {
      orderDetailsBy.value = orderDetailsSearch.value.map((e: any) => {
        return {
          ...e,
          assignStems: getAssignStems(e.id),
          assignBoxes: getAssignBoxes(e.id),
          customer: e.order.customer,
          customerName: `[${e.order.customer.code}] ${e.order.customer.shortName}`,
          varietyId: e.variety.id,
          orderStems: e.stems || 0,
          orderBoxes: e.boxes || 0
        }
      })

      dataShowListByCustomer.value =
        treeviewLevel.value === 2
          ? groupTwoLevel(
              orderDetailsBy.value,
              ['orderStems', 'orderBoxes', 'assignStems', 'assignBoxes', 'orderedSpecialStems'],
              ['customerName']
            )
          : groupMultiLevel(
              orderDetailsBy.value,
              ['orderStems', 'orderBoxes', 'assignStems', 'assignBoxes', 'orderedSpecialStems'],
              ['customerName', 'itemName', 'varietyName', 'qualitySizeName']
            )

      dataShowListByProduct.value =
        treeviewLevel.value === 2
          ? groupTwoLevel(
              orderDetailsBy.value,
              ['orderStems', 'orderBoxes', 'assignStems', 'assignBoxes', 'orderedSpecialStems'],
              ['itemName']
            )
          : groupMultiLevel(
              orderDetailsBy.value,
              ['orderStems', 'orderBoxes', 'assignStems', 'assignBoxes', 'orderedSpecialStems'],
              ['itemName', 'varietyName', 'qualitySizeName', 'customerName']
            )
      insertPackingStems()
    }

    const getData = async () => {
      loading.value = true
      await Promise.all([getOrderDetailAssign(), getPackingHarvest()]).finally(() => {
        loading.value = false
      })

      processData()
    }

    const handleSearch = debounce((value: string) => {
      searchInfo.value = value
      if (searchInfo.value === '') {
        orderDetailsSearch.value = JSON.parse(JSON.stringify(orderDetails.value))
      } else {
        const text = convertToWidthCharacter(searchInfo.value, 'full')
        orderDetailsSearch.value = orderDetails.value.filter((e: any) => {
          const { item, variety, size, quality } = e
          return (
            item.searchStr?.includes(text) ||
            variety.searchStr?.includes(text) ||
            size.searchStr?.includes(text) ||
            quality.searchStr?.includes(text)
          )
        })
      }
      rewriteUrl()
      processData()
    }, 500)

    const begin = async () => {
      await getData()
      handleSearch(searchInfo.value)
      searchOrderByHistory()
    }

    const reloadPackingResult = async () => {
      await getPackingHarvest()
      insertPackingStems()
    }

    onMounted(async () => {
      getSettingLevel()
      if (
        !(
          $store.state.common.latestSelectedDates.auctionDate &&
          $store.state.common.latestSelectedDates.packingDateRange
        ) &&
        !isFromCalendar
      )
        await getNextAuctionDate()
      const showValueState = $store.state.order.showValue
      if (Object.keys(showValueState).length) {
        isShowByBox.value = showValueState.isShowByBox
        isShowRemaining.value = showValueState.isShowRemaining
        isShowTotal.value = showValueState.isShowTotal
      } else {
        getSettingDefault()
      }
      await begin()
    })

    const goToOrderDetail = (
      orderId: number,
      orderDetailId: number,
      isDuplicated: boolean | null = false
    ) => {
      $router
        .push({
          name: urlPath.ORDER_DETAIL_FORM.name,
          params: {
            orderId: orderId.toString(),
            detailId: orderDetailId.toString(),
            auctionDate: date.value.toString(),
            isDuplicated: isDuplicated ? '1' : '0'
          },
          query: { called: 'true' }
        })
        .catch((e) => {
          console.log(e)
        })
    }

    const handleCreateOrderAndOrderDetail = () => {
      $router
        .push({
          name: urlPath.ORDER_DETAIL_FORM.name,
          params: {
            orderId: '0',
            detailId: '0',
            auctionDate: date.value.toString()
          },
          query: { called: 'true' }
        })
        .catch((e) => {
          console.log(e)
        })
    }

    const handleShowAction = (
      level1: string,
      level2: string,
      level3: string,
      isByCustomer: boolean
    ) => {
      if (isByCustomer) {
        thisCustomerName.value = level1
        thisItemName.value = level2
        thisVarietyName.value = level3
      }
      if (!isByCustomer) {
        thisItemName.value = level1
        thisVarietyName.value = level2
        thisQualitySizeName.value = level3
      }
      isShowAction.value = true
      isThisByCustomer.value = isByCustomer
    }

    const handleExpandAll = () => {
      if (isThisByCustomer.value) {
        panelStateCustomer.value = expandAll(
          dataShowListByCustomer.value,
          panelStateCustomer.value,
          [thisCustomerName.value, thisItemName.value, thisVarietyName.value],
          ['customerName', 'itemName', 'varietyName']
        )
      } else {
        panelStateProduct.value = expandAll(
          dataShowListByProduct.value,
          panelStateProduct.value,
          [thisItemName.value, thisVarietyName.value, thisQualitySizeName.value],
          ['itemName', 'varietyName', 'qualitySizeName']
        )
      }
      isShowAction.value = false
    }

    const handleCollapseAll = () => {
      if (isThisByCustomer.value) {
        panelStateCustomer.value = collapseAll(
          dataShowListByCustomer.value,
          panelStateCustomer.value,
          [thisCustomerName.value, thisItemName.value, thisVarietyName.value],
          ['customerName', 'itemName', 'varietyName']
        )
      } else {
        panelStateProduct.value = collapseAll(
          dataShowListByProduct.value,
          panelStateProduct.value,
          [thisItemName.value, thisVarietyName.value, thisQualitySizeName.value],
          ['itemName', 'varietyName', 'qualitySizeName']
        )
      }
      isShowAction.value = false
    }

    const goToDate = (isAuction: boolean) => {
      rewriteUrl()
      if (isAuction) {
        begin()
      } else {
        reloadPackingResult()
      }
      const latestSelectedDates = {
        auctionDate: date.value.toString(),
        packingDateRange: [auctionDateStart.value, auctionDateEnd.value]
      }
      // eslint-disable-next-line no-restricted-globals
      // parent.postMessage(latestSelectedDates, '*')
      framebus.emit(frameBusEvent.DATE, latestSelectedDates)
    }

    const movePanelStateCustomerToState = (data: any) => {
      $store.state.panelStateCustomer = data
    }
    const movePanelStateProductToState = (data: any) => {
      $store.state.panelStateProduct = data
    }

    const setDate = (start: string, end: string) => {
      auctionDateStart.value = start
      auctionDateEnd.value = end
    }

    const updateSetting = (
      isShowTotalValue: boolean,
      isShowByBoxValue: boolean,
      isShowRemainingValue: boolean
    ) => {
      isShowByBox.value = isShowByBoxValue
      isShowTotal.value = isShowTotalValue
      isShowRemaining.value = isShowRemainingValue
      isShowSetting.value = false
      // update state
      $store.commit('setShowValueOrder', {
        isShowByBox: isShowByBoxValue,
        isShowTotal: isShowTotalValue,
        isShowRemaining: isShowRemainingValue
      })
      getData()
    }
    const deleteDetail = async (): Promise<boolean> => {
      try {
        await api.delete(`${endpoints.ORDERS}${selectedOrderId.value}`)
        await api.delete(`${endpoints.ORDER_DETAILS}${selectedOrderDetailId.value}`)
        $toast.success(root.$t('common.msg.delete_success'))
        return true
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure'))
      }
      return false
    }
    const confirmDelete = async (action: string): Promise<void> => {
      let success = false
      if (action === 'delete') {
        success = await deleteDetail()
      }
      if (success) {
        getSettingLevel()
        begin()
      }
      showDelete.value = false
    }

    return {
      date,
      showBy,
      selectItems,
      loading,
      searchHistory,
      searchInfo,
      dataShowListByCustomer,
      dataShowListByProduct,
      orderDetailsByHistory,
      searchOrderByHistory,
      panelStateProduct,
      panelStateCustomer,
      selectedOrderId,
      selectedOrderDetailId,
      goToOrderDetail,
      handleCreateOrderAndOrderDetail,
      movePanelStateCustomerToState,
      movePanelStateProductToState,
      isShowAction,
      handleShowAction,
      handleExpandAll,
      handleCollapseAll,
      rewriteUrl,
      handleSearch,
      auctionDateStart,
      auctionDateStartModal,
      auctionDateEnd,
      updateAuctionDateStart,
      auctionDateEndModal,
      auctionDateOrderModal,
      begin,
      reloadPackingResult,
      isShowByBox,
      isShowTotal,
      isShowRemaining,
      isShowSetting,
      updateSetting,
      goToDate,
      dateRange,
      setDate,
      getNextAuctionDate,
      treeviewLevel,
      getSettingLevel,
      showDelete,
      confirmDelete
    }
  }
})

export default OrderByAuction
